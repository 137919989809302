import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import Api from "./components/APIs/Api";
import Login from "./components/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import URL from "./config";
import "./fonts/Poppins-Bold.ttf";
import "./fonts/Poppins-ExtraBold.ttf";
import "./fonts/Poppins-Light.ttf";
import "./fonts/Poppins-Medium.ttf";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Poppins-SemiBold.ttf";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ReactDOM.render(
  <div
    className="cookies-container text-center"
    style={{ background: "linear-gradient(to right,#140E38,#194089)" }}
  >
    {/* <div className="loader-box" style={{ position: "relative", top: "42%" }}>
      <div style={{color:"rgb(108, 99, 255)", width:"50px", height:"50px"}} class="spinner-border" role="status">
      </div>
    </div> */}
    <div className="loader-container">
      <div class="kinetic"></div>
    </div>
  </div>,
  document.querySelector("#root")
);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
// const token_new = urlParams.get("token");

function setCookie(name, value, daysToLive) {
  var cookie = name + "=" + encodeURIComponent(value);
  if (typeof daysToLive === "number") {
    cookie += "; max-age=" + daysToLive * 24 * 60 * 60;
    document.cookie = cookie;
  }
}

function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function delete_cookie(name) {
  document.cookie =
    name +
    "=; Domain=finstreet.in; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

//production
var token = getCookie("token");
// var token =
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZW51bWJlciI6IjgyOTk3NTQyNTAiLCJpYXQiOjE2MjUyMDM5NDAsImV4cCI6MTYzMDM4Nzk0MH0.2W0r0ID0ijX7qULiYbEJ2TwhzRq6f53eiF_reHVNYlM";
//testing copy token from the test site cookie after login
// var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZW51bWJlciI6Ijk1MzA1MTI3NDgiLCJpYXQiOjE2MTk5MzcwMzUsImV4cCI6MTYyNTEyMTAzNX0.zxEqrtS16BzcNAQQ08pKtRrSnDNqARblN3iKCQJWG_s'
// var token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZW51bWJlciI6IjgyOTk3NTQyNTAiLCJpYXQiOjE2MjUyMDM5NDAsImV4cCI6MTYzMDM4Nzk0MH0.2W0r0ID0ijX7qULiYbEJ2TwhzRq6f53eiF_reHVNYlM";

if (token) {
  fetchUser();
} else {
  delete_cookie("token");
  window.location.replace(URL + "/partner-login");
}

// var signedIn = getCookie('isSignedIn')
// if (!token_new){
//   window.location.replace(URL + '/partner-login');
// }
// else{
//   if (!signedIn){
//     setCookie('isSignedIn','true',30)
//     if(!token){
//       setCookie('token', token_new, 30);
//       fetchUser();
//       window.location.reload();
//     }
//     else if (token===token_new) {
//       fetchUser();
//     }else if(token !== token_new){
//       setCookie('token', token_new, 30);
//       fetchUser();
//       window.location.reload();
//     }else{
//       window.location.replace(URL + '/partner-login');
//     }
//   }
//   else if (signedIn === 'true') {
//     if(!token){
//       setCookie('token', token_new, 30);
//       fetchUser();
//       window.location.reload();
//     }
//     else if (token === token_new) {
//       fetchUser();
//     }else if(token !== token_new){
//       setCookie('token', token_new, 30);
//       fetchUser();
//       window.location.reload();
//     }else{
//       window.location.replace(URL + '/partner-login');
//     }
//   }
//   else if (signedIn === 'false'){
//     if (!token) {
//       document.cookie = 'isSignedIn=true';
//       setCookie('token',token_new,30);
//       fetchUser();
//       window.location.reload()
//     }
//     else if (token) {
//       if (token === token_new){
//         window.location.replace(URL + '/partner-login');
//       }
//       else{
//         document.cookie = 'isSignedIn=true';
//         setCookie('token',token_new,30);
//         fetchUser();
//         window.location.reload()
//       }
//     }
//   }
// }

async function fetchUser() {
  const response = await Api.get(`/userinfo?token=${token}`);
  if (response.data.status === "failure") {
    delete_cookie("token");
    ReactDOM.render(<Login />, document.querySelector("#root"));
  } else {
    ReactDOM.render(
      <App token={token} user={response.data} />,
      document.querySelector("#root")
    );
  }
}
