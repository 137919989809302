import React from "react";
import {Navbar, Nav} from "react-bootstrap";
import logodark from "../images/logodark.png";
import logolight from "../images/logolight.png";
import Api from "./APIs/Api";
import $ from "jquery";
import URL from "../config";

class NavigationBar extends React.Component {
  state = {
    view: window.innerWidth
  };

  scrollAnimation = () => {
    // console.log(this.state.view);
    if (this.state.view > 700) {
      $(window).scroll(function() {
        var sticky = $("#Navigation"),
          scroll = $(window).scrollTop();
        if (scroll >= 100) {
          sticky.addClass("fixed shadow w-100").removeClass("bg-shade");
          $("#Navigation a.userName").removeClass("color-white");
          $(".logo.align-top").attr("src", logolight);
        } else {
          sticky.removeClass("fixed shadow w-100").addClass("bg-shade");
          $("#Navigation a.userName").addClass("color-white");
          $(".logo.align-top").attr("src", logodark);
        }
      });
    } else {
      var sticky = $("#Navigation");
      sticky.removeClass("fixed shadow w-100").addClass("bg-shade");
      $("#Navigation a.userName").addClass("color-white");
      $(".logo.align-top").attr("src", logodark);
    }
  };

  delete_cookie(name) {
    document.cookie =
      name +
      "=; Domain=finstreet.in; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  onLogOut = async () => {
    await Api.post("/logout");
    document.cookie = "isSignedIn=false; ";
    this.delete_cookie("token");
    window.location.replace(URL + "/partner-logout");
  };

  handleSizeChange = () => {
    this.setState({view: window.innerWidth});
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleSizeChange);
    this.scrollAnimation();
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    if (this.state.view > 700) {
      return (
        <div className="container top-animation">
          <Navbar collapseOnSelect expand="lg" bg="#" variant="light" style={{paddingTop:"25px",paddingBottom:"25px"}}>
            <Navbar.Brand href={URL} className="py-0 f-20 border-right pr-4">
              <img
                src={logodark}
                width="125"
                className="d-inline-block align-top logo"
                alt="Finstreet"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{background:"linear-gradient(40deg, rgb(191, 104, 230) 20%, rgb(158, 72, 205) 51%, rgb(191, 104, 230) 90%)",color:"black"}}/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto ml-auto hide">
                <Nav.Link href={`${URL}/about-us`} className="f-16 m-2 active">
                  About Us
                </Nav.Link>
                <Nav.Link href={`${URL}/courses`} className="f-16 m-2 active">
                  Courses
                </Nav.Link>
                <Nav.Link href={`${URL}/partner`} className="f-16 m-2 active">
                  Partner with Us
                </Nav.Link>
                <Nav.Link href={`${URL}/contact`} className="f-16 m-2 active">
                  Contact Us
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link className="p-3 active text-capitalize userName">{`Hi, ${this.props.user.full_name}`}</Nav.Link>
                <Nav.Link eventKey={2}>
                  <button
                    className="btn"
                    onClick={this.onLogOut}
                    // style={{backgroundColor: "#6c63ff", color: "white"}}
                    style={{  backgroundImage: "linear-gradient(40deg,#BF68E6 20%,#9E48CD 51%,#BF68E6 90%)",
                      boxShadow:" 0 0 12px 0 #9e48cd", color: "white"}}
                  >
                    Log Out
                  </button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      );
    } else if (this.state.view < 700) {
      return (
        <>
          <div className="container bg-shade">
            <Navbar bg="#" variant="light">
              <Navbar.Brand href={`${URL}/`} className="py-0 f-20 mx-auto">
                <img
                  src={logodark}
                  width="110px"
                  // height="30"
                  className="d-inline-block align-top"
                  alt="Finstreet"
                />
              </Navbar.Brand>
            </Navbar>
          </div>
        </>
      );
    }
  }
}

export default NavigationBar;

const renderNav = (redeem, fn) => {
  if (redeem) {
    return (
      <>
        <Nav.Link
          onClick={() => {
            if (redeem) {
              fn();
            }
          }}
          style={{color: "black"}}
          href="#top-div"
          className="f-12  mx-1 text-center "
        >
          <div className="nav-icon f-24">
            <i className="fas fa-user-friends"></i>
          </div>
          Overview
        </Nav.Link>
        <Nav.Link
          onClick={() => {
            if (!redeem) {
              fn();
            }
          }}
          style={{color: "black"}}
          href="#Redeem"
          className="f-12  mx-1 text-center "
        >
          <div className="nav-icon f-24" style={{color:"#00008B"}}>
            <i className="fas fa-gift"></i>
          </div>
          <p className="mb-0" style={{color: "black"}}>
            Redeem
          </p>
        </Nav.Link>
      </>
    );
  } else {
    return (
      <>
        <Nav.Link
          onClick={() => {
            if (redeem) {
              fn();
            }
          }}
          style={{color: "black"}}
          href="#top-div"
          role="button"
          className="f-12 mx-1 text-center "
        >
          <div style={{color: "#00008B"}} className="nav-icon f-24">
            <i className="fas fa-user-friends"></i>
          </div>
          <p className="mb-0" style={{color: "black"}}>
            Overview
          </p>
        </Nav.Link>
        <Nav.Link
          onClick={() => {
            if (!redeem) {
              fn();
            }
          }}
          style={{color: "black"}}
          href="#Redeem"
          role="button"
          className="f-12 mx-1 text-center "
        >
          <div className="nav-icon f-24">
            <i className="fas fa-gift"></i>
          </div>
          Redeem
        </Nav.Link>
      </>
    );
  }
};

const delete_cookie = name => {
  document.cookie =
    name +
    "=; Domain=finstreet.in; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const onLogOut = async () => {
  await Api.post("/logout");
  document.cookie = "isSignedIn=false; ";
  delete_cookie("token");
  window.location.replace(URL + "/partner-logout");
};

export const BottomNav = props => {
  $(window).scroll(function() {
    if (
      $(window).scrollTop() + $(window).height() >
      $(document).height() - 410
    ) {
      // $(".fixed-bottom").css("display", "none");

      $(".fixed-bottom").fadeOut(300);
    } else {
      $(".fixed-bottom").fadeIn(300);
      // $(".fixed-bottom").css("display", "block");
    }
  });

  return (
    <div
      id="footer-nav"
      className="justify-content-center mobile-nav-bottom fixed-bottom shadow tile"
      style={{background: "white"}}
    >
      <Navbar
        className="justify-content-center mobile-nav-bottom my-0 py-0 back-w"
        bg="light"
        variant="light"
      >
        <Nav>
          <Nav.Link
            style={{color: "black"}}
            href={`${URL}/`}
            className="f-12 mx-1 text-center "
          >
            <div className="nav-icon f-24">
              <i className="fas fa-home"></i>
            </div>
            Home
          </Nav.Link>
          {renderNav(props.redeem, props.toggleFn)}
          <Nav.Link
            style={{color: "black"}}
            href="https://wa.me/917717303384/?text=Hello%2C%20I%20am%20facing%20an%20issue%20with%20the%20partner%20dashboard"
            className="f-12 mx-1 text-center "
          >
            <div className="nav-icon f-24">
              <i className="fab fa-whatsapp"></i>
            </div>
            Manager
          </Nav.Link>
        </Nav>
      </Navbar>
      <div
        className="logout-mobile justify-content-center"
        style={{backgroundColor: "#6C63FF"}}
      >
        <button
          onClick={onLogOut}
          className="btn error p-3 w-100 mt-0 text-capitalize"
          style={{borderRadius: "0", color: "white"}}
        >
          Hi {props.user.full_name}, Click Here to Logout
        </button>
      </div>
    </div>
  );
};
