import React from "react";
import FloatingWidget, {MobileTiles} from "./FloatingWidget";
import Footer from "./Footer";
import Api from "./APIs/Api";
import NavigationBar, {BottomNav} from "./NavigationBar";
import RedeemHome from "./RedeemHome";
import DashboardHome from "./DashboardHome";
import $ from "jquery";

class App extends React.Component {
  state = {
    redeem: false,
    user_data: this.props.user,
    total_bonus: null,
    view: window.innerWidth
  };

  handleSizeChange = () => {
    this.setState({view: window.innerWidth});
  };

  fetchtotalBonus = async () => {
    const response = await Api.get(
      `/total_bonus?phonenumber=${this.state.user_data.User.mobile_no}&referral_id=${this.state.user_data.User.referral_code}`
    );
    this.setState({total_bonus: response.data});
  };
  
  componentDidMount() {
    this.fetchtotalBonus();
    window.addEventListener("resize", this.handleSizeChange);
    //scroll
    const animL = document.querySelectorAll('.scrollersL');
    const animR = document.querySelectorAll('.scrollersR');
    const animT = document.querySelectorAll('.scrollersT');
    const animB = document.querySelectorAll('.scrollersB');
    window.addEventListener('scroll', checkBoxes);
    checkBoxes();
    function checkBoxes() {
      const triggerBottom = window.innerHeight / 3 * 4;
      animL.forEach(anim => {
        const boxTop = anim.getBoundingClientRect().top;
        if (boxTop < triggerBottom)
          anim.classList.add('showL');
        else
          anim.classList.remove('showL');
      })
      animR.forEach(anim => {
        const boxTop = anim.getBoundingClientRect().top;
        if (boxTop < triggerBottom)
          anim.classList.add('showR');
        else
          anim.classList.remove('showR');
      })
      animT.forEach(anim => {
        const boxTop = anim.getBoundingClientRect().top;
        if (boxTop < triggerBottom)
          anim.classList.add('showT');
        else
          anim.classList.remove('showT');
      })
      animB.forEach(anim => {
        const boxTop = anim.getBoundingClientRect().top;
        if (boxTop < triggerBottom)
          anim.classList.add('showB');
        else
          anim.classList.remove('showB');
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  onClick = () => {
    this.setState({redeem: !this.state.redeem});
  };

  redeembtntxt = () => {
    if (this.state.redeem) {
      return "Earn More Now";
    } else {
      return "Redeem Bonus";
    }
  };

  renderBody1 = () => {
    if (this.state.redeem) {
      return (
        <RedeemHome
          width={this.state.view}
          user={this.renderUser()}
          resetfn={this.onClick}
          totalBonus={this.sendTotalBonus()}
        />
      );
    } else {
      return (
        <DashboardHome
          width={this.state.view}
          user={this.renderUser()}
          totalBonus={this.sendTotalBonus()}
        />
      );
    }
  };

  renderUser = () => {
    if (!this.state.user_data) {
      return {
        full_name: "",
        mobile_no: "",
        referral_code: "",
        user_id: "",
        total_unique_visitors: "",
        user_total_clicks: ""
      };
    } else {
      return this.state.user_data.User;
    }
  };

  sendTotalBonus = () => {
    if (!this.state.total_bonus) {
      return {
        total_bonus_earned: "loading",
        total_bonus_redeemable: "loading",
        total_bonus_redeemed: "loading"
      };
    } else {
      return this.state.total_bonus;
    }
  };

  render() {
    return (
      <div>
        <div id="Navigation">
          <NavigationBar user={this.renderUser()} />
        </div>
        <div className="content-body">
          <div className="bg-shade">
            <div className="container">
              <div>
                <h1 id="top-div" className="p-medium scrollersL">
                  Welcome to Your Partner Dashboard
                </h1>
              </div>
              <FloatingWidget
                user={this.renderUser()}
                totalBonus={this.sendTotalBonus()}
                btntext={this.redeembtntxt()}
                clickbtn={this.onClick}
              />
            </div>
          </div>
          <div className="m-show container">
            <h5 className="p-bold p-2"><br/> {""}</h5>
            {MobileTiles(
              4,
              this.redeembtntxt(),
              this.props.user,
              this.onClick,
              this.sendTotalBonus()
            )}
          </div>
          {this.renderBody1()}
          <div className="container body-3 shadow scrollersB">
            <h1>Having trouble Getting Engagement on Your Link?</h1>
            <p className="help mt-justify">
              If you are facing trouble relating to promotion of link or need
              some personalized assets to increase your Referral Power don't
              forget to Contact your Partner Manager
            </p>
            <a
              href="https://wa.me/917717303384/?text=Hello%2C%20I%20am%20facing%20an%20issue%20with%20the%20partner%20dashboard"
              className="btn btn-link btn-xl mb-btn"
              style={{color: "white"}}
            >
              Connect with your Partner Manager
            </a>
          </div>
        </div>
        <div className="m-show container">
          <BottomNav
            toggleFn={this.onClick}
            redeem={this.state.redeem}
            user={this.renderUser()}
          />
        </div>
        <div id="footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
