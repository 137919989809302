import React from "react";
import {Form, Row, Col} from "react-bootstrap";
import Api from "./APIs/Api";
import paytm from "../images/paytm.png";
import UPI from "../images/UPI.png";
import bank from "../images/bank.png";
import $ from "jquery";

var current = new Date();
var curr_date = `${current.getDate()}/${current.getMonth() +
  1}/${current.getFullYear()}`;
//--------------------------------------------------- browser name ---------------------//
var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName = navigator.appName;
var fullVersion = "" + parseFloat(navigator.appVersion);
var majorVersion = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;

// In Opera, the true version is after "Opera" or after "Version"
if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
  browserName = "Opera";
  fullVersion = nAgt.substring(verOffset + 6);
  if ((verOffset = nAgt.indexOf("Version")) !== -1)
    fullVersion = nAgt.substring(verOffset + 8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
  browserName = "Microsoft Internet Explorer";
  fullVersion = nAgt.substring(verOffset + 5);
}
// In Chrome, the true version is after "Chrome"
else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
  browserName = "Chrome";
  fullVersion = nAgt.substring(verOffset + 7);
}
// In Safari, the true version is after "Safari" or after "Version"
else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
  browserName = "Safari";
  fullVersion = nAgt.substring(verOffset + 7);
  if ((verOffset = nAgt.indexOf("Version")) !== -1)
    fullVersion = nAgt.substring(verOffset + 8);
}
// In Firefox, the true version is after "Firefox"
else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
  browserName = "Firefox";
  fullVersion = nAgt.substring(verOffset + 8);
}
// In most other browsers, "name/version" is at the end of userAgent
else if (
  (nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))
) {
  browserName = nAgt.substring(nameOffset, verOffset);
  fullVersion = nAgt.substring(verOffset + 1);
  if (browserName.toLowerCase() === browserName.toUpperCase()) {
    browserName = navigator.appName;
  }
}
// trim the fullVersion string at semicolon/space if present
if ((ix = fullVersion.indexOf(";")) !== -1)
  fullVersion = fullVersion.substring(0, ix);
if ((ix = fullVersion.indexOf(" ")) !== -1)
  fullVersion = fullVersion.substring(0, ix);

majorVersion = parseInt("" + fullVersion, 10);
if (isNaN(majorVersion)) {
  fullVersion = "" + parseFloat(navigator.appVersion);
  majorVersion = parseInt(navigator.appVersion, 10);
}

// ip
var ip = [];
const getIp = () => {
  $.getJSON("https://jsonip.com?callback=?", function(data) {
    ip.push(data.ip);
  });
};

getIp();

class DetailForm extends React.Component {
  state = {
    name: "",
    phoneNumber: "",
    upiID: "",
    acc_no: "",
    ifsc: ""
  };

  componentDidMount() {
    // console.log(this.props.type);
  }

  onNameChange = evt => {
    this.setState({name: evt.target.value});
  };

  onMobileNoChange = evt => {
    this.setState({phoneNumber: evt.target.value});
  };

  onAccNoChange = evt => {
    this.setState({acc_no: evt.target.value});
  };

  onifscChange = evt => {
    this.setState({ifsc: evt.target.value});
  };

  onUpiChange = evt => {
    this.setState({upiID: evt.target.value});
  };

  createURL = () => {
    switch (this.props.type) {
      case "GooglePay":
        return `/redeem_requests?user_id=${this.props.user.user_id}&phonenumber=${this.props.user.mobile_no}&gpay_number=${this.state.phoneNumber}&account_name=${this.state.name}&redeem_amount=${this.props.totalBonus.total_bonus_redeemable}&from_ip=${ip[0]}&from_browser=${browserName}&time=${curr_date}&method=Gpay`;
      case "UPI":
        if (
          this.state.phoneNumber === "" ||
          this.state.name === "" ||
          this.state.upiID === ""
        ) {
          alert("Fill all the details to submit a redeem request.");
          return "Error";
        }
        return `/redeem_requests?user_id=${this.props.user.user_id}&phonenumber=${this.props.user.mobile_no}&upi_number=${this.state.phoneNumber}&account_name=${this.state.name}&upi_id=${this.state.upiID}&redeem_amount=${this.props.totalBonus.total_bonus_redeemable}&from_ip=${ip[0]}&from_browser=${browserName}&time=${curr_date}&method=UPI`;
      case "Paytm":
        if (this.state.phoneNumber === "" || this.state.name === "") {
          alert("Fill all the details to submit a redeem request.");
          return "Error";
        }
        return `/redeem_requests?user_id=${this.props.user.user_id}&phonenumber=${this.props.user.mobile_no}&paytm_number=${this.state.phoneNumber}&account_name=${this.state.name}&redeem_amount=${this.props.totalBonus.total_bonus_redeemable}&from_ip=${ip[0]}&from_browser=${browserName}&time=${curr_date}&method=Paytm`;
      case "Bank":
        if (
          this.state.phoneNumber === "" ||
          this.state.name === "" ||
          this.state.acc_no === "" ||
          this.state.ifsc === ""
        ) {
          alert("Fill all the details to submit a redeem request.");
          return "Error";
        }
        return `/redeem_requests?user_id=${this.props.user.user_id}&account_number=${this.state.acc_no}&account_name=${this.state.name}t&account_ifsc=${this.state.ifsc}&phonenumber=${this.state.phoneNumber}&redeem_amount=${this.props.totalBonus.total_bonus_redeemable}&from_ip=${ip[0]}&from_browser=${browserName}&time=${curr_date}&method=Account`;
      default:
    }
  };

  submitData = async () => {
    const url = this.createURL();
    if (url !== "Error") {
      const response = await Api.post(url);
      this.props.clickfn(response.data);
    }
  };

  renderInputs = () => {
    switch (this.props.type) {
      case "Paytm":
        return (
          <Row>
            <Col md={6} xs={12}>
              <Form>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Recipient's Name</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={this.onNameChange.bind(this)}
                    placeholder="Enter Name"
                    value={`${this.state.name}`}
                  />
                  <Form.Text className="text-muted">
                    Note: Please enter the recipient name that is associated
                    with the account.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicNumber">
                  <Form.Label>
                    Recipient's {`${this.props.type}'s linked `} Mobile Number
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Mobile Number"
                    onChange={this.onMobileNoChange.bind(this)}
                    value={`${this.state.phoneNumber}`}
                  />
                </Form.Group>
                <button
                  className="btn btn-redeem p-2 w-100 mt-5"
                  onClick={event => {
                    event.preventDefault();
                    this.submitData();
                  }}
                  type="submit"
                  style={{
                    color: "white"
                  }}
                >
                  Submit Request
                </button>
              </Form>
              <p className="f-16 mt-3 text-justify">
                By Clicking the "Submit Button" You Declare that the Details
                Filled above are Correct to the best of your Knowledge and You
                Agree to the Terms & Condictions of finstreet.in <br /> If
                You Are Facing Any Problems or need Guidance Don't Forget to
                {` `}
                <a
                  style={{color: "#00008B", textDecoration: "none"}}
                  href="https://wa.me/917717303384/?text=Hello%2C%20I%20am%20facing%20an%20issue%20with%20the%20partner%20dashboard"
                >
                  contact us
                </a>
              </p>
            </Col>
            <Col md={6} xs={12}>
              <Row>
                <div
                  onClick={this.props.toggleForm}
                  id="UPI"
                  className="tile my-tile p-3 m-2 bg-light ml-auto mr-auto"
                >
                  <div id="UPI" className="icon py-4">
                    <img id="UPI" src={UPI} className="align-top" alt="icon" />
                  </div>
                  <div id="UPI" className="f-22 p-medium">
                    Redeem Using UPI
                  </div>
                </div>
              </Row>
              <Row>
                <div
                  onClick={this.props.toggleForm}
                  id="Bank"
                  className="tile my-tile p-3 m-2 bg-light ml-auto mr-auto"
                >
                  <div id="Bank" className="icon">
                    <img
                      id="Bank"
                      src={bank}
                      className="align-top"
                      alt="icon"
                    />
                  </div>
                  <div id="Bank" className="f-22 p-medium">
                    Redeem Using Bank account
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        );
      case "UPI":
        return (
          <Row>
            <Col xs={12} md={6}>
              <Form>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Recipient's Name</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={this.onNameChange.bind(this)}
                    placeholder="Enter Name"
                    value={`${this.state.name}`}
                  />
                  <Form.Text className="text-muted">
                    Note: Please enter the recipient name that is associated
                    with the account.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicNumber">
                  <Form.Label>
                    Recipient's {`${this.props.type}'s linked `} Mobile Number
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Mobile Number"
                    onChange={this.onMobileNoChange.bind(this)}
                    value={`${this.state.phoneNumber}`}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicNumber">
                  <Form.Label>Recipient's UPI ID</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Mobile Number"
                    onChange={this.onUpiChange.bind(this)}
                    value={`${this.state.upiID}`}
                  />
                </Form.Group>
                <button
                  className="btn btn-redeem p-2 mt-5 mb-2 w-100"
                  onClick={event => {
                    event.preventDefault();
                    this.submitData();
                  }}
                  type="submit"
                  style={{
                    color: "white"
                  }}
                >
                  Submit Request
                </button>
              </Form>
              <p className="f-16 mt-3 text-justify">
                By Clicking the "Submit Button" You Declare that the Details
                Filled above are Correct to the best of your Knowledge and You
                Agree to the Terms & Condictions of finstreet.in <br /> If
                You Are Facing Any Problems or need Guidance Don't Forget to
                {` `}
                <a
                  style={{color: "##00008B", textDecoration: "none"}}
                  href="https://wa.me/917717303384/?text=Hello%2C%20I%20am%20facing%20an%20issue%20with%20the%20partner%20dashboard"
                >
                  contact us
                </a>
              </p>
            </Col>
            <Col md={6} xs={12}>
              <Row>
                <div
                  onClick={this.props.toggleForm}
                  id="Paytm"
                  className="tile my-tile p-3 m-2 bg-light ml-auto mr-auto"
                >
                  <div id="Paytm" className="icon">
                    <img
                      id="Paytm"
                      src={paytm}
                      className="align-top"
                      alt="icon"
                    />
                  </div>
                  <div id="Paytm" className="f-22 p-medium">
                    Redeem Using Paytm
                  </div>
                </div>
              </Row>
              <Row>
                <div
                  onClick={this.props.toggleForm}
                  id="Bank"
                  className="tile my-tile p-3 m-2 bg-light ml-auto mr-auto"
                >
                  <div id="Bank" className="icon">
                    <img
                      id="Bank"
                      src={bank}
                      className="align-top"
                      alt="icon"
                    />
                  </div>
                  <div id="Bank" className="f-22 p-medium">
                    Redeem Using Bank account
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        );
      case "Bank":
        return (
          <Row>
            <Col xs={12} md={6}>
              <Form>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Recipient's Name</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={this.onNameChange.bind(this)}
                    placeholder="Enter Name"
                    value={`${this.state.name}`}
                  />
                  <Form.Text className="text-muted">
                    Note: Please enter the recipient name that is associated
                    with the account.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicNumber">
                  <Form.Label>
                    Recipient's {`${this.props.type}'s linked `} Mobile Number
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Mobile Number"
                    onChange={this.onMobileNoChange.bind(this)}
                    value={`${this.state.phoneNumber}`}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicDetail1">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Account Number"
                    onChange={this.onAccNoChange.bind(this)}
                    value={`${this.state.acc_no}`}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicDetail">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter IFSC code"
                    onChange={this.onifscChange.bind(this)}
                    value={`${this.state.ifsc}`}
                  />
                </Form.Group>
                <button
                  className="btn btn-redeem p-2 mt-4 mb-2 w-100"
                  onClick={event => {
                    event.preventDefault();
                    this.submitData();
                  }}
                  type="submit"
                  style={{
                    color: "white"
                  }}
                >
                  Submit Request
                </button>
              </Form>
              <p className="f-16 mt-3 text-justify">
                By Clicking the "Submit Button" You Declare that the Details
                Filled above are Correct to the best of your Knowledge and You
                Agree to the Terms & Condictions of finstreet.in <br /> If
                You Are Facing Any Problems or need Guidance Don't Forget to
                {` `}
                <a
                  style={{color: "#00008B", textDecoration: "none"}}
                  href="https://wa.me/917717303384/?text=Hello%2C%20I%20am%20facing%20an%20issue%20with%20the%20partner%20dashboard"
                >
                  contact us
                </a>
              </p>
            </Col>
            <Col md={6} xs={12}>
              <Row>
                <div
                  onClick={this.props.toggleForm}
                  id="Paytm"
                  className="tile my-tile p-3 m-2 bg-light ml-auto mr-auto"
                >
                  <div id="Paytm" className="icon">
                    <img
                      id="Paytm"
                      src={paytm}
                      className="align-top"
                      alt="icon"
                    />
                  </div>
                  <div id="Paytm" className="f-22 p-medium">
                    Redeem Using Paytm
                  </div>
                </div>
              </Row>
              <Row>
                <div
                  onClick={this.props.toggleForm}
                  id="UPI"
                  className="tile my-tile p-3 m-2 bg-light ml-auto mr-auto"
                >
                  <div id="UPI" className="icon py-4">
                    <img id="UPI" src={UPI} className="align-top" alt="icon" />
                  </div>
                  <div id="UPI" className="f-22 p-medium">
                    Redeem Using UPI
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        );
      default:
    }
  };

  render() {
    return <div>{this.renderInputs()}</div>;
  }
}

export default DetailForm;

//
