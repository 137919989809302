import React from "react";
import logo from "../images/logo.png";
import URL from "../config";
import pattern from '../images/Pattern.svg';
import "./footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div id="Footer" className="footer">
        <footer>
        <img class="pattern-left" src={pattern} alt="partern"/>
          <div className="container">
            <div className=" d-flex align-items-center justify-content-between m-hidden ">
              <a href={URL}>
                <img src={logo} style={{paddingTop:"30px", paddingBottom:"30px"}} width="125px" alt="logo" />
              </a>
              <div>
                <nav className="mobile-nav">
                  <a className="p-medium" href={`${URL}/about-us`}>
                    About Us
                  </a>
                  <a className="p-medium" href={`${URL}/courses`}>
                    Our Courses
                  </a>
                  <a className="p-medium" href={`${URL}/partner`}>
                    Partner With Us
                  </a>
                  <a className="p-medium" href={`${URL}/contact-us`}>
                    Contact Us
                  </a>
                  <a className="p-medium" href={`${URL}/terms-and-conditions`}>
                    T&amp;C's
                  </a>
                  <a className="p-medium" href={`${URL}/sitemap.xml`}>
                    Sitemap
                  </a>
                </nav>
              </div>
            </div>
            <div className=" pb-5 pt-3 dm-flex align-items-center justify-content-between m-show row ">
              <a className=" mx-0 col-12 text-center" href={URL}>
                <img src={logo} width="125px" style={{margin:"30px 0"}} alt="logo" />
              </a>
              <div className="w-100">
                <nav className="mobile-nav col-12 pl-fcust">
                  <a className="p-medium" href={`${URL}/about-us`}>
                    About Us
                  </a>
                  <a className="p-medium" href={`${URL}/courses`}>
                    Our Courses
                  </a>
                  <a className="p-medium" href={`${URL}/partner`}>
                    Partner With Us
                  </a>
                  <a className="p-medium" href={`${URL}/contact-us`}>
                    Contact Us
                  </a>
                  <a className="p-medium" href={`${URL}/terms-and-conditions`}>
                    T&amp;C's
                  </a>
                  <a className="p-medium" href={`${URL}/sitemap.xml`}>
                    Sitemap
                  </a>
                </nav>
              </div>
            </div>
            <div className="pt-5 pb-3 border-top-footer d-flex align-items-center justify-content-between">
              <h6 className="p-regular">
                © 2020-2025 Quadb Technologies. All rights reserved
              </h6>
              <div className="social f-20">
              <a href='https://www.youtube.com/channel/UC9uE3NWbpg09xN8H5pmT4gQ' className='footer-link col p-0'><i className="fab fa-youtube"></i></a> <a href='https://www.facebook.com/finstreet.in' className='footer-link col p-0'><i className="fab fa-facebook-f"></i></a>  <a href='https://www.instagram.com/finstreet.in/' className='footer-link col p-0'><i className="fab fa-instagram"></i></a> <a href='https://telegram.me/finstreet.in' className='footer-link col p-0'><i className="fab fa-telegram-plane"></i></a>
              </div>
            </div>
          </div>
          <img class="pattern-right" src={pattern} alt="partern"></img>
        </footer>
      </div>
    );
  }
}

export default Footer;
