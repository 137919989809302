import React from 'react'
import URL from '../config'

class Card extends React.Component {
  componentDidMount(){
    const animB = document.querySelectorAll('.scrollersB');
    window.addEventListener('scroll', checkBoxes);
    checkBoxes();
    function checkBoxes() {
      const triggerBottom = window.innerHeight / 2.5 * 4;

      animB.forEach(anim => {
        const boxTop = anim.getBoundingClientRect().top;
 
        if (boxTop < triggerBottom)
          anim.classList.add('showB');
        else
          anim.classList.remove('showB');
      })
  }
}


  copyText = () => {
    var copyText = document.getElementById(`${this.props.course.course_id}`);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
  }

  render() {
    return (
      <div className="scrollersB">
      <div className="card shadow">
        <table>
          <tbody>
            <tr>
              <td style={{ width: '100px' }}><img src={`${URL}/${this.props.course.course_thumb_img}`} className="cover-auto" alt="..." width='100px' /></td>
              <td>
                <h5 className='f-18 p-medium text-left'>{`${this.props.course.course_name}`}</h5>
                <div><img src={`${URL}/${this.props.course.state_logo}`}  width='24px' alt='course-img' />
                <span class="img-description poppins-regular text-uppercase">{this.props.course.category_type}</span>
                  {/* <p className='img-description text-uppercase'> {this.props.course.category_type}</p> */}
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className='container'>
          <div className='row'>
            {CardGrid('Total refferals earned', this.props.course.referral_earned, 'blue')}
            {CardGrid('Total bonus earned', this.props.course.bonus_earned, 'green')}
          </div>
          <div className='row'>
            {CardGrid('Total clicks on link', this.props.course.number_of_clicks, 'red')}
            {CardGrid('Total visitors through link', this.props.course.unique_visitors, '#f68c20')}
          </div>
        </div>

        <div className="card-body">
          <h3 className="card-title f-24 p-semi-bold">Gain a bonus of Rs. {this.props.course.max_referral_amount} on every successfull referral</h3>
          <p className="card-text text-muted f-14">The Link Below is your Referral link. You will gain a referral bonus each time a visitor enrolls using your given link, so please don't forget to Share it on all platforms.<br />If You Are facing any problems or need Guidance  Don't Forget to <a href='https://wa.me/917717303384/?text=Hello%2C%20I%20am%20facing%20an%20issue%20with%20the%20partner%20dashboard'>contact us</a></p>
        </div>
        <div className='card-footer'>
          <input readOnly id={this.props.course.course_id} value={`${URL}/single-course-page?course_id=${this.props.course.course_id}&ref=${this.props.reff}`} className='text-muted w-75 p-regular f-12' style={{ height: '39px', background: '#EFEFF6', border: '1px solid #CAC6D4', borderBottomLeftRadius: '3px' }} ></input>
          <button onClick={this.copyText} className='btn btn-link w-25 p-medium' style={{ color: 'white' }} >Copy</button>
        </div>
      </div>
      </div> 
    );
  }

}



export default Card;

export const CardGrid = (title, count, color = '') => {
  return (
    <div className='col c-grid' style={{ border: `1px solid ${color}` }}>
      <div className='text-muted'><div className='c-grid-content p-semi-bold'>{count}</div><p>{title}</p></div>
    </div>
  );
}
