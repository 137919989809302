import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import paytm from "../images/paytm.png";
import UPI from "../images/UPI.png";
import bank from "../images/bank.png";
class FloatingWidget extends React.Component {
  render() {
    return (
      <div className="bg-white m-hidden tile scrollersT">
        <ul className="list-group list-group-horizontal txt-center">
          <li className="list-group-item flex-fill f-16 p-regular">
            <div className="widget-top-align p-semi-bold f-20 text-capitalize">
              Welcome {this.props.user.full_name}
            </div>
          </li>
          <li className="list-group-item flex-fill f-16 p-regular">
            <div className="f-20 p-semi-bold">{this.props.user.users_total_clicks}</div>
            Total clicks <br />
            Accumilated
          </li>
          <li className="list-group-item flex-fill f-16 p-regular">
            <div className="f-20 p-semi-bold">{this.props.user.total_unique_visitors}</div>
            Total visitors <br />
            invited
          </li>
          <li className="list-group-item flex-fill f-16 p-bold">
            <div className="f-20">{this.props.totalBonus.total_bonus_earned}</div>Total Bonus{" "}
            <br />
            Earned
          </li>
          <li className="list-group-item flex-fill f-16 p-regular">
            <div className="f-20 p-semi-bold">
              {this.props.totalBonus.total_bonus_redeemable}
            </div>
            Total Redeemable
            <br /> Bonus
          </li>
          <li className="list-group-item flex-fill f-16 p-regular">
            <button
              onClick={this.props.clickbtn}
              href="#"
              style={{color: "white"}}
              className="btn btn-redeem p-medium p-3"
            >
              {this.props.btntext}
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default FloatingWidget;

export const MobileTiles = (number, btntext = "", user = null, clickFn, totalBonus) => {
  if (user) {
    return (
      <>
      <Container>
        <Row>
          <Col style={{background:'white'}} className="tile" xs={6} md={12 / number}>
            <div className="p-semi-bold">{user.User.users_total_clicks}</div>Total clicks <br />
            Accumilated
          </Col>
          <Col style={{background:'white'}} className="tile" xs={6} md={12 / number}>
            <div  className="p-semi-bold">{user.User.total_unique_visitors}</div>Total visitors{" "}
            <br />
            invited
          </Col>
          <Col style={{background:'white'}} className="tile p-bold" xs={6} md={12 / number}>
            <div className="">{totalBonus.total_bonus_earned}</div>Total Bonus <br />
            Earned
          </Col>
          <Col style={{background:'white'}} className="tile" xs={6} md={12 / number}>
            <div className="f-20 p-semi-bold">{totalBonus.total_bonus_redeemable}</div>Total
            Redeemable Bonus
          </Col>
        </Row>
      </Container>
      <button
        href="#"
        style={{color: "white"}}
        onClick={clickFn}
        className="btn btn-redeem p-medium p-3 w-100"
      >
        {btntext}
      </button>
      </>
    );
  } else {
    return (
    <div className='container' >
      <Row>
        <Col
          id="Paytm"
          className="tile p-3 m-2 back-w ml-auto mr-auto"
          style={{borderRadius:'5px'}}
          onClick={clickFn}
          xs={10}
          md={12 / number}
        >
          <div id="Paytm" className="icon p-2">
            <img id="Paytm" src={paytm} className="align-top" alt="icon" />
          </div>
          <div id="Paytm" className="f-22 p-2 p-medium">Redeem Using Paytm</div>
        </Col>
        <Col
          id="UPI"
          className="tile p-3 m-2 back-w ml-auto mr-auto"
          style={{borderRadius:'10px'}}
          onClick={clickFn}
          xs={10}
          md={12 / number}
        >
          <div id="UPI" className="icon p-2 py-4">
            <img id="UPI" src={UPI} className="align-top" alt="icon" />
          </div>
          <div id="UPI" className="f-22 p-2 p-medium">Redeem Using UPI</div>
        </Col>
        <Col
          id="Bank"
          className="tile p-3 py-4 m-2 back-w ml-auto mr-auto"
          style={{borderRadius:'5px'}}
          onClick={clickFn}
          xs={10}
          md={12 / number}
        >
          <div id="Bank" className="icon p-2">
            <img id="Bank" src={bank} className="align-top" alt="icon" />
          </div>
          <div id="Bank" className="f-22 p-2 p-medium">Redeem Using Bank Account</div>
        </Col>
      </Row>
    </div>
    );
  }
};
