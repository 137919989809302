import React from 'react';
import RewardBlock from "./RewardBlock";
import Card  from "./Card";
import Api from './APIs/Api'

class DashboardHome extends React.Component{

  state = {
    course1: null,
    course2:null,
    course3: null,
    bonus_history_data: null,
    courses: null
  }

  fetchData = async () => {
    const response = await Api.get(`/affiliate_courses?user_referral_code=${this.props.user.referral_code}`);
    this.setState({ courses:response.data.data, course1: response.data.data[0], course2: response.data.data[1], course3: response.data.data[2]});
    this.fetchBonusHistory();
  }

  fetchBonusHistory = async () =>  {
    const response = await Api.get(`/bonus_history?referral_id=${this.props.user.referral_code}`);
    this.setState({bonus_history_data: response.data});
  }

  componentDidMount(){
    this.fetchData();
    // const boxes = document.querySelectorAll('.card');
    // window.addEventListener('scroll', checkBoxes);
    // checkBoxes();
    // function checkBoxes() {
    //   const triggerBottom = window.innerHeight / 4 * 4;
    //   boxes.forEach(box => {
    //     const boxTop = box.getBoundingClientRect().top;
    //     if (boxTop < triggerBottom)
    //       box.classList.add('show');
    //     else
    //       box.classList.remove('show');
    //   })
    // }
  }



  renderCards = () => {
    if (!this.state.courses){
      return(
        <div className="row ">
          <div className="col-4 col-sm-4">
            <Card course={this.renderCourse(this.state.course1)}  />
          </div>
          <div className="col-4">
            <Card course={this.renderCourse(this.state.course2)} />
          </div>
          <div className="col-4">
            <Card course={this.renderCourse(this.state.course3)} />
          </div>
        </div>
      );
    }
    else{
      return(this.state.courses.map((course) => {
        return(
          <div key={course.course_id} className='col-md-4 col-12 my-2'>
            <Card course={course} reff={this.props.user.referral_code} />
          </div>
        );
      }));
    }
  }

  renderCourse = (course) => {
    if (course === null){
      return( {
        course_name: 'loading',
        number_of_clicks: 'loading',
        unique_visitors: 'loading'
      });
    }
    else{
      return course ;
    }
  }


  renderRewardBlock = () => {
    if (!this.state.bonus_history_data){
      return 'loading'
    }
    else{
      if (this.state.bonus_history_data.purchaseData.length === 0){
        return (
          <RewardBlock
            width={this.props.width}
            reward="Rs. 0"
            deadline='-'
            text="You haven't recieved any bonus till now"
            btntext="Earn Now"
            type='redeem'
          />
        );
      }
      else{
        return (
          this.state.bonus_history_data.purchaseData.map((data) => {
            return (
              <RewardBlock
                width={this.props.width}
                key={data.product_id}
                reward={`Rs. ${data.amount}`}
                deadline={data.date_now.slice(0,10)}
                text={<span><span className='p-semi-bold text-capitalize'>{data.purchased_by}</span> purchased <span className='p-semi-bold'>{data.course_name}</span> course from your referral</span>}
                btntext="bonus"
                type='bonus'
              />
            )
          })
        )
      }
    }
  }

  render(){
    return (
      <div className="container body-2">
        <h1 className='p-medium scrollersR' style={{color:"black"}} >Refferal opportunities</h1>
        <div className='row mx-0'>
        {this.renderCards()}
        </div>
        <div className='m-t-c'>
          <h1 className="scrollersL" style={{display: "inline-block", color:"black"}}>Your Bonus History</h1>
          <div className="reward-header m-t-c">
            <p className="text-muted scrollersB" style={{color:"white"}}>
              You have recieved Rs. {this.props.totalBonus.total_bonus_earned} till now.
            </p>
          </div>
        </div>
        {this.renderRewardBlock()}
      </div>
    );
  }

}


export default DashboardHome;
