import React from "react";
import Api from "./APIs/Api";
import RewardBlock from "./RewardBlock";
import {MobileTiles} from "./FloatingWidget";
import DetailForm from "./DetailForm";

class RedeemHome extends React.Component {
  state = {
    details: false,
    successDetail: false,
    redeem_history_data: null,
    type: "",
    err: ""
  };

  fetchRedeemHistory = async () => {
    const response = await Api.get(
      `/redeem_history?user_id=${this.props.user.user_id}`
    );
    this.setState({redeem_history_data: response.data});
  };

  onTypeToggle = evt => {
    this.setState({type: evt.target.id});
  };

  onDetailToggle = evt => {
    this.setState({type: evt.target.id});
    // console.log(evt);
    this.setState({details: !this.state.details});
  };

  onSuccessDetailToggle = evt => {
    this.setState({successDetail: !this.state.successDetail});
  };

  stateReset = () => {
    this.props.resetfn();
    this.setState({details: false});
    this.setState({successDetail: false});
    this.setState({type: ""});
  };

  toggleBoth = data => {
    this.setState({details: !this.state.details});
    this.setState({successDetail: !this.state.successDetail});
    if (data.status === "failure") {
      this.setState({err: data.msg});
    }
  };

  componentDidMount() {
    this.fetchRedeemHistory();
  }
  

  renderRewardBlock = () => {
    if (!this.state.redeem_history_data) {
      return "loading";
    } else {
      if (this.state.redeem_history_data.data.length === 0) {
        return (
          <RewardBlock
            width={this.props.width}
            reward="Rs. 0"
            deadline="-"
            text="You haven't redeemed any bonus till now"
            btntext="Earn Now"
            type="redeem"
          />
        );
      } else {
        return this.state.redeem_history_data.data.map(t => {
          return (
            <RewardBlock
              width={this.props.width}
              key={this.state.redeem_history_data.data.indexOf(t)}
              reward={`Rs. ${t.redeem_amount}`}
              deadline={`${t.time}`}
              text={`Withdrawn using ${t.method}`}
              btntext={`${t.status}`}
              type={`${t.status}`}
            />
          );
        });
      }
    }
  };

  renderSection = () => {
    if (!this.state.details && !this.state.successDetail) {
      return (
        <div>
          <h1 className="p-medium" style={{color:"black"}}>Claim Your Partner Bonus</h1>
          <div className="Container m-cont">
            {MobileTiles(4, "", null, this.onDetailToggle)}
          </div>
        </div>
      );
    } else if (this.state.details && !this.state.successDetail) {
      return (
        <div>
          <h1 className="p-medium" style={{padding: "55px 0px 20px", color:"black"}}>
            Please Enter Your Details
          </h1>
          <p className="f-16 mt-5 text-justify" style={{color: "#302D3A"}}>
            Please Fill in Your Redeem Information, Once the Redeem Request is
            Submitted Your "Total Bonus Redeemable Amount" will be transferred
            to the Recipient within the next 24Hrs and The Amount Shown as Total
            Bonus Redeemable will be 0 Please Note to change the details of the
            redeem request you will need to contact your Partner Manager.
          </p>
          <div className="Container-fluid">
            <DetailForm
              clickfn={this.toggleBoth}
              type={this.state.type}
              user={this.props.user}
              toggleForm={this.onTypeToggle}
              totalBonus={this.props.totalBonus}
            />
          </div>
        </div>
      );
    } else if (!this.state.details && this.state.successDetail) {
      if (this.state.err === "") {
        return (
          <div>
            <h2
              className="p-medium f-40 m-header-success"
              style={{padding: "55px 0px 20px"}}
            >
              You Have successfully submitted a Redeem Request
            </h2>
            <p className="f-16 mt-2 text-justify">
              The Redeem request has been successfully submitted and is
              currently under processing. The Request will be processed within
              the next 24 hours. If there are any discrepancies please make sure
              to contact your partner manager. if the redeem request doesn't
              appear in your redeem history please refresh the page or try
              logging in once again, if the problem persists please get in touch
              with your Partner Manager
            </p>
            <button
              className="btn btn-redeem p-3 mt-2 w-50"
              onClick={this.stateReset}
              type="submit"
              style={{color: "white"}}
            >
              Start Earning More Now
            </button>
          </div>
        );
      } else {
        alert(`${this.state.err}`);
        this.setState({successDetail: false});
        return (
          <div>
            <h2
              className="p-medium f-40 m-header-success"
              style={{padding: "55px 0px 20px"}}
            >
              {this.state.err}
            </h2>
            <p className="f-16 mt-2 text-justify">
              Your previous Redeem request has been successfully submitted and
              is currently under processing. Once the request is completed you
              will be able to make new redeem request. If there are any
              discrepancies please make sure to contact your partner manager. if
              the redeem request doesn't appear in your redeem history please
              refresh the page or try logging in once again, if the problem
              persists please get in touch with your Partner Manager
            </p>
            <button
              className="btn btn-redeem p-3 mt-2 w-50"
              onClick={this.stateReset}
              type="submit"
              style={{color: "white"}}
            >
              Start Earning More Now
            </button>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <div id="Redeem" className="container body-2">
        {this.renderSection()}
        <div className="m-t-c">
          <h1 style={{display: "inline-block"}}>Your Redeem History</h1>
          <div className="reward-header m-t-c page-r">
            <p className="text-muted">
              {`You have redeemed Rs. ${this.props.totalBonus.total_bonus_redeemed} till now.`}
            </p>
          </div>
        </div>
        {this.renderRewardBlock()}
      </div>
    );
  }
}

export default RedeemHome;
