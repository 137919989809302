import React from "react";
import {Row, Col} from 'react-bootstrap'

const RewardBlock = props => {

  const Block = (color) => {
    if (props.width > 425){
      return (
        <div className=''>
          <Row>
            <Col xs={2} className='f-16 pt-2 p-semi-bold'>
              {props.reward}
            </Col>
            <Col xs={6} className='f-16 pt-2'>
              {props.text}
            </Col>
            <Col xs={2} style={{paddingLeft:'5px'  }} className='f-16 pt-2'>
              <i className="far fa-clock"></i>
              {props.deadline}
            </Col>
            <Col xs={2} className='f-16'>
              <button className="btn btn-link disabled text-capitalize" style={{backgroundColor: `${color}`, color:'white',width:'110px'}} >{props.btntext}</button>
            </Col>
          </Row>
        </div>
      );
    }
    else{
      return (
            <div className=''>
              <Row>
                <Col xs={12} className='f-16 pt-2'>
                  {props.text}
                </Col>
                <Col xs={12} className='f-16 pt-2 p-semi-bold'>
                  {props.reward}
                </Col>
                <Col xs={6} style={{paddingLeft:'5px' }} className='f-16 pt-2'>
                  <i className="far fa-clock"></i>
                  {props.deadline}
                </Col>
                <Col xs={6} className='f-16 text-right'>
                  <button className="btn btn-link disabled text-capitalize" style={{backgroundColor: `${color}`, color:'white',width:'110px'}} >{props.btntext}</button>
                </Col>
              </Row>
            </div>
      );
    }
  }
  if (props.type === 'bonus' || props.type === 'Paid'){
    return (
      <div className="container floating-div shadow" style={{background: "white"}}>
        {Block('#43C8B1')}
      </div>
    );
  }
  if (props.type === 'redeem'){
    return (
      <div className="container floating-div shadow" style={{background: "white"}}>
        {Block('#F56962')}
      </div>
    );
  }
  if (props.type === 'Pending'){
    return (
      <div className="container floating-div shadow" style={{background: "white"}}>
        {Block('#F68C20')}
      </div>
    );
  }
};

export default RewardBlock;
